import React from 'react';
import styled from '@emotion/styled';

const Content = styled.div<{nbCol?: number}>`
    float: left;
    width: ${props => {
        if (!props.nbCol) return '100%'
        if (props.nbCol === 4) return '25%';
        if (props.nbCol === 3) return '33.33%';
        if (props.nbCol === 2) return '50%';
        return '100%';
    }};
    
    @media (max-width: 768px) {
        width: ${props => {
            if (!props.nbCol) return '100%';
            if (props.nbCol === 4) return '50%';
            return '100%';
        }};
    }
    
    @media (max-width: 425px) {
        width: 100%;
    }
`;

interface ColumnProps {
    children: React.ReactNode;
    nbCol?: number;
    customStyle?: React.CSSProperties;
    className?: string | undefined;
}

const Column: any = (props: ColumnProps) => {
    return (
        <Content className={props.className} style={props.customStyle} nbCol={props.nbCol}>
            {props.children}
        </Content>
    );
}

export default Column;

