export const setCookie = (name: string, value: string, days: number) => {
    const date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
}

export const getCookie = (name: string) => {
    const finder = `${name}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (const value of ca) {
        let c = value;
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(finder) == 0) {
            return c.substring(finder.length, c.length);
        }
    }
    return '';
}