import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import Row from '../templates/Row';
import Column from '../templates/Column';

const Content = styled.div`
    width: 100%;
    padding: 40px 0;
    position: relative;
    background-color: #333333;
`;

const Copyright = styled.div`
    display: block;
    color: white;
    text-align: center;
`;

const LinkArea = styled.div`
    display: block;
    text-align: center;
    margin-bottom: 10px;
`;

const LinkCustom = styled(Link)`
    color: white;
    text-decoration: none;
`;

const ContactArea = styled.div`
    display: block;
    text-align: center;
`;

const MailTo = styled.a`
    color: white;
    text-decoration: none;
`;

interface FooterProps {

}

const Footer: any = (props: FooterProps) => {
    return (
        <Content>
            <Row>
                <Column nbCol={3}>
                    <Copyright>&copy; collectoogo 2020</Copyright>
                </Column>
                <Column nbCol={3}>
                    <ContactArea>
                        <MailTo href="mailto:contact@collectoogo.fr">contact@collectoogo.fr</MailTo>
                    </ContactArea>
                </Column>
                <Column nbCol={3}>
                    <LinkArea>
                        <LinkCustom to="/tcu">
                            C.G.U / Mentions légales
                        </LinkCustom>
                    </LinkArea>
                    <LinkArea>
                        <LinkCustom to="/credits">
                            Crédits
                        </LinkCustom>
                    </LinkArea>
                </Column>
            </Row>
        </Content>
    );
}

export default Footer;