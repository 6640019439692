import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { getCookie, setCookie } from '../../utils/cookie';

const Content = styled.div`
    position: fixed;
    bottom: 35px;
    right: 20px;
    margin-left: 20px;
    max-width: 300px;
    z-index: 10;
    border: 1px solid #b2ebf2;
`;

const Header = styled.div`
    padding: 10px 15px;
    background: #00838f;
    font-size: 1.5em;
    color: white;
`;

const Container = styled.div`
    padding: 15px;
    background-color: #b2ebf2;
`;

const CloseBtn = styled.span`
    position: absolute;
    top: 5px;
    right: 15px;
    color: white;
    font-size: 30px;
    cursor: pointer;
  
    &:hover {
        color: #b2ebf2;
    }
`;

const Text = styled.p`
    
`;

interface CalloutProps {

}

const Callout: any = (props: CalloutProps) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (!getCookie('info')) {
            setVisible(true);
        }
        setTimeout(() => {
            setVisible(false);
            setCookie('info', 'ok', 1);
        }, 10000);
    }, []);

    return (
        <>
            {visible && (
                <Content>
                    <Header>Informations</Header>
                    <CloseBtn
                        onClick={() => {
                            setVisible(false);
                            setCookie('info', 'ok', 30);
                        }}>
                        x
                    </CloseBtn>
                    <Container>
                        <Text>
                            Collectoogo est <b>gratuit</b> pendant la phase de beta test.
                        </Text>
                    </Container>
                </Content>
            )}
        </>
    );
}

export default Callout;