import React from 'react';
import styled from '@emotion/styled';

const Content = styled.div`
    &:after {
        content: "";
        display: table;
        clear: both;
    }
`;

interface RowProps {
    children: React.ReactNode;
    className?: string | undefined;
}

const Row: any = (props: RowProps) => {
    return (
        <Content className={props.className}>
            {props.children}
        </Content>
    );
}

export default Row;