import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { getCookie, setCookie } from '../../utils/cookie';

const Content = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 5;
  background-color: white;
  color: #00838f;
  font-size: 0.9em;
  padding: 7px 0;
`;

const Text = styled.p`
    padding: 0 20px;
    text-align: justify;
`;

const LinkCustom = styled(Link)`
    margin-left: 5px;
    color: #00838f;
`;

const ButtonArea = styled.div`
    text-align: center;
`;

const ButtonActive = styled.button`
    border: 1px solid #00838f;
    border-radius: 5px;
    background-color: #00838f;
    color: white;
    padding: 7px 10px;
    cursor: pointer;
    font-size: 0.9em;
    
    &:hover, &:active, &:focus {
        background-color: white;
        color: #00838f;
        outline: none;
    }
`;

interface CookiesProps {

}

const Cookies: any = (props: CookiesProps) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (!getCookie('cookies')) {
            setVisible(true);
        }
    }, []);

    return (
        <>
            {visible && (
                <Content>
                    <Text>
                        En cliquant sur ”J’accepte”, vous acceptez l’utilisation des cookies. Vous pourrez toujours les désactiver ultérieurement. Si vous supprimez ou désactivez nos cookies, vous pourriez rencontrer des interruptions ou des problèmes d’accès au site.
                        <LinkCustom to="/cookies">En savoir plus</LinkCustom>
                    </Text>
                    <ButtonArea>
                        <ButtonActive
                            onClick={() => {
                                setVisible(false);
                                setCookie('cookies', 'ok', 30);
                            }}>
                            J'accepte
                        </ButtonActive>
                    </ButtonArea>
                </Content>
            )}
        </>
    );
}

export default Cookies;