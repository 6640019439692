import React, { ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import Callout from '../callout/Callout';
import Cookies from '../cookies/Cookies';

const MenuArea = styled.div`
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
    overflow: hidden;
    background-color: #00838f;
    border-bottom: 1px solid #b2ebf2;
`;

const MenuHover = styled.div`
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 1;
    background-color: rgba(0,0,0,0.3);
`;

const Left = styled.div<{isBurgerMenu: boolean;}>`
    display: ${props => !props.isBurgerMenu ? 'inherit' : 'block !important'};
    float: ${props => !props.isBurgerMenu ? 'left' : 'none !important'};
    margin-left: ${props => !props.isBurgerMenu ? 'inherit' : 'auto !important'};
    margin-right: ${props => !props.isBurgerMenu ? 'inherit' : 'auto !important'};
    width: ${props => !props.isBurgerMenu ? 'fit-content' : '100% !important'};
    
    @media (max-width: 1200px) {
        width: 100% !important;
    }
`;

const Center = styled.div<{isBurgerMenu: boolean;}>`
    width: ${props => !props.isBurgerMenu ? 'fit-content' : '100% !important'};
    margin-right: auto;
    margin-left: auto;
    display: ${props => !props.isBurgerMenu ? 'inherit' : 'block !important'};
    
    @media (max-width: 1200px) {
        display: ${props => !props.isBurgerMenu ? 'none' : 'block !important'};
    }
`;

const Right = styled.div`
    display: none;
    float: right;
    position: absolute;
    right: 0;
    top: 0;
    padding: 25.5px 20px;
    
    @media (max-width: 1200px) {
        display: block;
    }
    
    &:hover {
        cursor: pointer;
        background-color: #b2ebf2;
        color: #00838f;
    }
`;

const BurgerMenuElem = styled.div`
    background-color: white;
    width: 25px;
    height: 3px;
    margin: 4px 0;
`;

const LogoArea = styled(Link)<{isBurgerMenu: boolean;}>`
    text-decoration: none;
    display: ${props => !props.isBurgerMenu ? 'inherit' : 'block !important'};
    width: ${props => !props.isBurgerMenu ? 'auto' : '100% !important'};
    padding-left: ${props => !props.isBurgerMenu ? 'inherit' : '0 !important'};
    padding-right: ${props => !props.isBurgerMenu ? 'inherit' : '0 !important'};
    min-height: 75px;
`;

const Logo = styled.img`
    width: 80px;
    padding-top: 7px;
    padding-left: 7px;
    padding-bottom: 5px;
`;

const LogoText = styled.span`
    color: white;
    padding: 0 20px;
    font-size: 30px;
    font-weight: 500;
    display: block;
    margin-left: 85px;
    margin-top: -50px;
    
    @media (max-width: 1200px) {
        font-size: 25px;
        margin-top: -47px;
        margin-left: 0;
        text-align: center;
    }
`;

const ItemArea = styled(Link)<{isBurgerMenu: boolean;}>`
    display: ${props => !props.isBurgerMenu ? 'inline-flex' : 'block !important'};
    width: ${props => !props.isBurgerMenu ? 'fit-content' : '100% !important'};
    text-decoration: none;
    color: white;
    text-align: center;
    padding: ${props => !props.isBurgerMenu ? '28px 20px' : '28px 0px !important'};
    font-size: 17px;
    font-weight: 500;
    
    &:hover {
        background-color: #b2ebf2;
        color: #00838f;
    }
`;

interface MenuProps {
    children: ReactNode;
    items: any[];
}

const Menu: any = (props: MenuProps) => {
    const {
        children,
        items
    } = props;

    const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

    return (
        <>
            <MenuArea>
                <Left isBurgerMenu={burgerMenuOpen}>
                    <LogoArea
                        to="/"
                        isBurgerMenu={burgerMenuOpen}>
                        <Logo src="/images/logo-collectoogo-white.svg"/>
                        <LogoText>
                            Collectoogo
                        </LogoText>
                    </LogoArea>
                </Left>
                <Center isBurgerMenu={burgerMenuOpen}>
                    {items.map(({node}, index) => (
                        <ItemArea
                            key={index}
                            to={node.linkTo}
                            isBurgerMenu={burgerMenuOpen}
                            activeStyle={{
                                backgroundColor: '#b2ebf2',
                                color: '#00838f'
                            }}
                            onClick={() => {
                                setBurgerMenuOpen(false);
                            }}>
                            {node.text}
                        </ItemArea>
                    ))}
                </Center>
                <Right onClick={() => {
                    setBurgerMenuOpen(!burgerMenuOpen);
                }}>
                    <BurgerMenuElem/>
                    <BurgerMenuElem/>
                    <BurgerMenuElem/>
                </Right>
            </MenuArea>
            {burgerMenuOpen && (
                <MenuHover onClick={() => {
                    setBurgerMenuOpen(false);
                }}/>
            )}
            <Callout/>
            <Cookies/>
            {children}
        </>
    );
}

export default Menu;